<template>
    <div class="dateline">
        <h1>{{ date_now.format("LL") }}</h1>
    </div>
</template>

<script>
export default {
    name: "dateline",
    props: ['date_now']
}
</script>

<style scoped>

.dateline {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 100vw;
    background-color: rgba(248, 171, 4, 1);
    color: white;
    font-family: 'MullerExtraBold', sans-serif;
}

.dateline h1 {
    margin: 0;
    width: 100%;
    text-align: center;
}
</style>