import Vue from 'vue'
import App from './App.vue'

import moment from 'moment/min/moment-with-locales'
import 'moment/locale/ru'

moment.locale('ru')

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
}).$mount('#app')
