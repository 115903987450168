<template>
    <div class="images-bg">
        <img src="../assets/image1.png" class="image1"/>
        <img src="../assets/image2.png" class="image2"/>
        <img src="../assets/image3.png" class="image3"/>
        <img src="../assets/image4.png" class="image4"/>
        <img src="../assets/image5.png" class="image5"/>
    </div>
</template>

<script>
export default {
name: "images"
}
</script>

<style scoped>
    .images-bg {
        width: 100vw;
        height: 100vh;
        position: fixed;
    }

    .images-bg img {
        position: absolute;
        filter: opacity(0.7);
        z-index: 1;
    }

    img.image1 {
        left: 70%;
        top: 65%;
        height: 30vh;
        width: 30vh;
        filter: opacity(1);
    }

    img.image2 {
        left: 35%;
        top: 25%;
        height: 27vh;
        width: 27vh;
    }

    img.image3 {
        left: 50%;
        top: 70%;
        height: 15vh;
        width: 15vh;
    }

    img.image4 {
        left: 65%;
        top: 25%;
        height: 25vh;
        width: 25vh;
    }

    img.image5 {
        left: 15%;
        top: 65%;
        height: 28vh;
        width: 28vh;
    }
</style>