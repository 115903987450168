<template>
    <div class="timeline">
        <h2>{{ timeline['name'] }}</h2>
        <div class="line" :class="{
            'labels': labels
        }">
            <div
                v-for="time in timeline['timeline']"
                :key="time.id"
                class="timearea"
                :class="{
                    'busy': time.isBusy,
                    'now': time.isNow
                    }"><span v-if="labels">{{ time.time.format('HH:mm') }}</span></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "timeline",
    props: ['timeline', 'date_now', 'labels'],
}
</script>

<style scoped>
.timeline {
    width: 100vw;
    display: flex;
    flex-direction: row;
}

.line {
    height: 100%;
    display: grid;
    overflow: hidden;
    /*grid-column: 1;*/
    grid-template-columns: repeat(27, 1fr);
    width: 90vw;
}

.timeline h2 {
    width: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
}

.timeline:nth-child(2n) {
    background: rgba(81, 114, 161, 0.5);
}

.timearea:last-of-type {
    border-right: none;
}

.timearea:first-of-type {
    border-left: 1px solid rgba(0, 160, 227, 1);
}

.timearea {
    border-right: 1px solid rgba(0, 160, 227, 1);
    background-color: transparent;
    transition: background-color 0.5s;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.timearea span {
    color: rgb(248, 171, 4);
    font-family: 'MullerExtraBold', sans-serif;
    height: 25%;
}

.now {
    background-color: rgba(0, 255, 0, 0.2);
}

.busy:after {
    content: '';
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(255,150,150,.7);
    margin-bottom: 40%;
    margin-top: 40%;
}

.labels .busy:after {
    margin-top: 0;
}


</style>