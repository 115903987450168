<template>
    <div class="timetable">
        <timeline
            v-for="(value, index) in data"
            :date_now="date_now"
            :key="index"
            :timeline="value"
            :labels="index == 0"
        />
    </div>
</template>

<script>
import Timeline from "@/components/timeline";
// import TimelineLabels from "@/components/timeline-labels";

export default {
    name: "timetable",
    components: { Timeline},
    props: ['data', 'date_now'],
}
</script>

<style scoped>
    .timetable {
        height: calc(100vh - 200px);
        display: grid;
        overflow: hidden;
        grid-auto-rows: 1fr;
        grid-column-gap: 5px;
    }
</style>