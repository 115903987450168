<template>
    <div id="app">
        <images/>
        <headerline></headerline>
        <dateline :date_now="date_now"></dateline>
        <timetable :data="data" :date_now="date_now"></timetable>
    </div>
</template>

<script>
import Headerline from "@/components/headerline";
import Dateline from "@/components/dateline";
import Timetable from "@/components/timetable";
import moment from 'moment';
import Images from "@/components/images";

export default {
    name: 'App',
    components: {
        Images,
        Timetable,
        Dateline,
        Headerline,
    },
    data() {
        return {
            date_now: moment(),
            data: [],
            url: 'https://api.itmostudents.ru/v1/screens/coworking',
            center: ''
        }
    },
    methods: {
        updateData: function () {
            const axios = require('axios');
            let self = this;
            axios.get(this.url).then(function (response) {
                let payload = response.data.data.data;
                let rooms = {}, sorooms = {};
                if (self.center === "Коворкинг Ломоносова") {
                    rooms = {
                        '1303/1': [],
                        '1303/2': [],
                        '1303/3': [],
                        '1303/4': [],
                        '1303/5': [],
                        '1303/6': [],
                        '1303/7': [],
                        '1303/8': [],
                    }
                } else if (self.center === "Коворкинг Кронверкский") {
                    rooms = {
                        '355/13': [],
                        '355/14': [],
                        '355/15': [],
                        '355/16': [],
                        '355/17': [],
                    }
                } else if (self.center === "Коворкинг Студенческого офиса - 1400 (ул. Ломоносова, д.9, лит.М)") {
                    rooms = {
                        '17': [],
                        '18': [],
                        '19': [],
                        '20': [],
                        '21': [],
                    }
                    sorooms = {
                        '1400д': '17',
                        '1400е': '18',
                        '1400ж': '19',
                        '1400з': '20',
                        '1400и': '21',
                    }
                }

                for (let key in rooms) {
                    let start = self.date_now;
                    start.set({hour: 7, minute: 30, second: 0, millisecond: 0})
                    for (let i = 0; i < 27; i++) {
                        start = moment(start).add(30, 'm');
                        let next_change = moment(start).add(30, 'm')
                        rooms[key].push({
                            'id': i,
                            'time': start,
                            'isBusy': false,
                            'isNow': moment().isBetween(start, next_change)
                        })
                    }
                }

                payload.forEach(function (payload_elem) {
                    if (payload_elem['center'] === self.center) {
                        if (self.center === "Коворкинг Студенческого офиса - 1400 (ул. Ломоносова, д.9, лит.М)") {
                            rooms[sorooms[payload_elem['room_number']]].forEach(function (elem) {
                                let datetime_start = moment(payload_elem['date_start'], 'DD.MM.YYYY HH:mm:ss'),
                                    datetime_end = moment(payload_elem['date_end'], 'DD.MM.YYYY HH:mm:ss');
                                elem['isBusy'] = elem['time'].isBetween(datetime_start, datetime_end, null, '[)') || elem['isBusy']
                            })
                        } else {
                            rooms[payload_elem['room_number']].forEach(function (elem) {
                                let datetime_start = moment(payload_elem['date_start'], 'DD.MM.YYYY HH:mm:ss'),
                                    datetime_end = moment(payload_elem['date_end'], 'DD.MM.YYYY HH:mm:ss');
                                elem['isBusy'] = elem['time'].isBetween(datetime_start, datetime_end, null, '[)') || elem['isBusy']
                            })
                        }
                    }
                })
                self.data = []
                for (var key in rooms) {
                    self.data.push({
                        'name': key,
                        'timeline': rooms[key]
                    })
                }
                self.data.sort(function (a, b) {
                    if (a['name'] < b['name']) {
                        return -1
                    } else {
                        return 1
                    }
                });
            })
            setTimeout(function () {
                self.updateData()
            }, 60 * 1000)
        }
    },
    created() {
        if (window.location.pathname === '/lm') {
            this.center = "Коворкинг Ломоносова"
        } else if (window.location.pathname === '/gk') {
            this.center = "Коворкинг Кронверкский"
        } else if (window.location.pathname === '/so') {
            this.center = "Коворкинг Студенческого офиса - 1400 (ул. Ломоносова, д.9, лит.М)"
        } else {
            window.location.href = '/gk'
        }
        this.updateData()
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background-color: rgba(40, 48, 94, 1);
    color: white;
    background-image: url("assets/bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

body, html, #app {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
}

div {
    z-index: 0;
}
</style>
