<template>
    <div class="headerline">
        <div class="left">
            <h1>Коворкинг</h1>
            <h2>Бронь переговорных</h2>
        </div>
        <div class="center">
            <qrcode-vue value="https://isu.ifmo.ru/pls/apex/f?p=2431:4:::NO:4:P4_MIN_DATE,P4_BACK_PAGE:,"
                        class="qrcode"/>
        </div>
        <div class="right">
            <img src="../assets/logo.png"/>
        </div>
    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
    name: "headerline",
    components: {
        QrcodeVue,
    }
}
</script>

<style scoped>
.headerline {
    width: 100vw;
    height: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.center .qrcode {
    width: fit-content;
    margin: 10px;
    padding: 5px 5px 0;
    background: white;
}

.right {
    justify-content: flex-end;
    width: 40%;
    height: 100%;
    display: flex;
}

.right img {
    width: 550px;
    height: 130px;
}

.left {
    flex-direction: column;
    padding-left: 50px;
    padding-top: 22.5px;
    display: flex;
    height: calc(100% - 22.5px);
    width: calc(40% - 50px);
}

.left h1, .left h2 {
    width: 100%;
    text-align: left;
    margin: 0;
    /*margin-left: 70px;*/
    font-family: 'MullerExtraBold', sans-serif;
}


.left h1 {
    text-transform: uppercase;
    font-size: 50px;
}

.left h2 {
    font-size: 35px;
}

</style>